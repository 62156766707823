.timeline {
  position: relative;
  &__item {
    @apply flex flex-wrap;

    &.progress {
      svg path,
      svg rect {
        fill: theme("colors.highlight");
      }
      height: 200%;
      width: 30px;
      content: "";
    }

    &.done {
      height: 30px;
      width: 30px;
    }

    &.idle {
      height: 200%;
      width: 30px;
      content: "";
    }

    &__description {
      @apply text-highlight text-base flex-1;
      margin-left: 9px;
      &.done {
        @apply text-secondary;
      }
      &.idle {
        @apply text-gray-400;
      }
    }

    &__date {
      @apply mb-6 text-caption;
      flex-basis: 100%; // date wraps on its own line
      color: text-gray-600;
      margin-left: 39px; // keep space for icon
    }
  }
}

//==========  Utility classes ==========//

// subtle 'fade in' to the lazyloaded image
[data-lazy] {
  @apply opacity-0 w-full;
  transition: opacity 0.25s ease-in;
  min-height: 120px;
}

.loaded {
  @apply opacity-100;
}

// Reset body visibility changed in default.njk style tag, which is loaded
// for dev environment to avoid flash of unstyled content on navigation
body {
  visibility: initial;
}

.company-logo-link {
  @apply text-white hidden w-8;

  @screen lg {
    @apply block mr-24;
  }

  @screen xl {
    @apply w-16;
  }
}

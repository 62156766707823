//========== Mobile Navigation ==========//

$transition-easing: cubic-bezier(0.77, 0, 0.175, 1);
$transition-length: 0.25s;

// menu toggle button
.mobile-nav-toggle {
  @apply block fixed h-12 w-full bottom-0 flex items-center justify-center font-bold border-none bg-highlight text-white z-50;

  @screen xs {
    @apply h-12;
  }

  @screen md {
    @apply hidden;
  }

  .mobile-nav-icon-line {
    height: 2px;
  }

  // when menu toggle is clicked
  &.menu-toggle-active {
    @apply bg-transparent;
    .mobile-nav-icon-bg {
      @apply bg-highlight rounded-full;
      width: 40px;
      height: 40px;
      position: absolute;
      margin-left: -2px;
    }

    .mobile-nav-icon-line {
      @apply m-0 bg-white;

      &:nth-child(1) {
        transform: rotate(45deg) translateY(3px);
      }

      &:nth-child(2) {
        @apply opacity-0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg) translateY(-3px);
      }
    }

    .mobile-nav-label {
      position: absolute;
      left: -100%;
    }
  }
}

.wrapper.menu-visible {
  display: none;
  @screen md {
    display: flex;
  }
}

// mobile menu
.mobile-nav {
  @apply fixed left-0 top-0 h-screen w-full overflow-y-auto pt-12 bg-dark z-50;
  height: -webkit-fill-available;
  transform: translate3d(-100%, 0, 0);
  transition: transform $transition-length $transition-easing;
  transition-delay: 0.1s;
  will-change: transform;

  @screen md {
    @apply hidden;
  }

  // when menu is visible
  &.menu-visible {
    transform: translate3d(0, 0, 0);

    .item {
      @apply opacity-100;
      transform: translate3d(0, 0, 0);
    }

    .footer {
      @apply opacity-100 duration-300;
      transition-delay: 1s;
    }
  }

  .item {
    transition: opacity 0.4s ease,
      transform $transition-length $transition-easing;
    transform: translate3d(-50%, 0, 0);
    transition-delay: $transition-length;

    // incrementally delay our animations
    @for $i from 0 through 100 {
      &:nth-child(#{$i}) {
        transition-delay: $transition-length * $i / 8 + 0.5;
      }
    }
  }

  .footer {
    @apply pt-3 px-5 fixed left-0 w-full z-50 overflow-y-auto;

    // bottom = height of .mobile-nav-toggle
    bottom: 3rem;
    @screen xs {
      bottom: 4rem;
    }

    @screen sm {
      @apply px-5 py-6;
    }

    @screen md {
      @apply hidden;
    }
  }
}

.content .donation-options {
  @apply flex flex-col my-10;

  @screen sm {
    @apply flex-row flex-wrap max-w-3xl;

    a {
      @apply mr-4;
    }
  }

  @screen lg {
    @apply my-16;
  }
}

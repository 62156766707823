//==========  Buttons ==========//
// a.button,
// .buttons a {
//   display: inline-block;
//   @apply border-2 border-white py-1 px-2 rounded-lg text-highlight;
//   font-weight: bold;
//   border-width: 1px;

//   @screen md {
//     @apply py-2 px-4;
//   }
// }

// .buttons {
//   @apply my-6;

//   a {
//     @apply mr-1;

//     @screen md {
//       @apply mr-2;
//     }
//   }
// }

.button-group {
  @apply flex flex-row;

  .btn {
    @apply rounded-none border-r-0 mr-0;
    margin-right: 0;
  }

  .btn:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .btn:last-child {
    @apply border;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}

a.btn {
  @apply rounded font-bold p-4 text-center;
  font-size: 14px;
  line-height: 16px;
  min-width: 10rem;
  display: inline-table;
  margin: 10px 1rem 10px 0;

  @apply border border-light text-light bg-transparent;

  &:hover {
    @apply bg-dark;
  }

  &.btn-active {
    @apply bg-light text-dark border-light;
  }

  &.btn-primary {
    @apply border-0 bg-secondary text-dark;

    &:hover {
      background-color: #65fbe5;
    }
  }

  &.btn-highlight {
    @apply border-0 bg-highlight text-dark;

  }


  &.btn-secondary {
    @apply border-0 bg-light text-dark;

    &:hover {
      @apply bg-white;
    }
  }

  &.btn-sm {
    @apply p-2;
    font-size: 12px;
    min-width: 7rem;

    &.btn-pill {
      @apply py-1 px-2;
    }
  }

  &.btn-lg {
    min-width: 12rem;
    @apply p-6;
  }

  &.btn-pill {
    @apply rounded-full py-3 px-6;

    min-width: 4rem;
  }
}

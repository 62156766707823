//==========  Links ==========//

a {
  @apply no-underline transition-all duration-300 ease-out;

  &:not([class]) {
    border-bottom-width: 1px;
    border-color: #ecf0f8;

    &:hover {
      @apply text-highlight border-black;
    }
  }
}

.markdown {
  .header-anchor {
    @apply text-dark transition-all duration-300 ease-out;
  }

  h1,
  h2,
  h3,
  h4 {
    &:hover .header-anchor {
      @apply text-white;
    }
  }
}

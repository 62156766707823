// Component for rendering a pattern preview that includes an image and
// a short text blurb below
.pattern-preview {
  @apply max-w-xs;

  img {
    @apply mb-4;
    width: 322px;
    height: 204px;
  }
}

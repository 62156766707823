// These style rules are written specifically for the dots report, however they
// are not scoped to that page

#report .content.markdown {
  // the take-aways component sits below a heading and contains the main take-
  // aways of the following paragraph

  p,
  ul,
  ol {
    @apply max-w-xl;
  }

  .take-aways {
    @apply mb-16;

    @screen md {
      @apply mb-24;
    }

    p {
      @apply text-base my-8;

      @screen md {
        @apply text-lg my-16;
      }
    }

    ul,
    ol {
      @apply pl-0 list-none;

      @screen md {
        @apply pl-8 list-none;

        li {
          @apply mb-8;
        }
      }
    }
  }

  // The table of contents is written as a regular markdown lists, this class
  // allows customizing the markdown list-styling to something that works for
  // a table of contents

  .markdown-toc {
    ul,
    ol {
      @apply my-4;
      li {
        @apply mt-4;
      }
    }
  }

  // the aside is a special element used in the dots report. it is implemented
  // here as an element with a dotted colored border and contains content that
  // sits "aside" to the main content of the report

  .aside {
    @apply py-8 px-6 my-16 border-dotted border-2 border-secondary;

    @screen md {
      @apply py-16 px-12;

      p {
        @apply mb-16;
      }
    }

    ul {
      @apply mb-0 pl-0;

      @screen md {
        @apply pl-8;
      }
    }

    ul li {
      @apply list-none font-bold mb-8;

      &:last-child {
        @apply mb-0;
      }

      ul {
        @apply list-outside pl-6;

        @screen md {
          @apply pl-0;
        }
      }

      ul li {
        @apply list-disc font-normal pl-2 mt-0 mb-0;
      }
    }
  }
}
